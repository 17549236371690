import { MailDataRequired } from "@sendgrid/mail";
import { Reducer } from "redux";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ProcessStatus } from "../const/enums/processStatus";

const actionCreator = actionCreatorFactory("MAIL");
export const sendStart = actionCreator<void>("SEND_START");
export const send = actionCreator.async<MailDataRequired, boolean, Error>(
  "SEND"
);
// const sendMailAsync = (
//   data: MailDataRequired
// ): Promise<[ClientResponse, {}]> => {
//   return sendgridMail.send(data);
// };

export type MailAction = typeof sendStart | typeof send;
export interface MailState {
  processStatus: ProcessStatus;
}

const initialState: MailState = {
  processStatus: ProcessStatus.idle,
};

export const mailReducer: Reducer<MailState, MailAction> =
  reducerWithInitialState(initialState)
    .case(sendStart, (state) => ({
      ...state,
      processStatus: ProcessStatus.processing,
    }))
    .case(send.started, (state) => ({
      ...state,
      processStatus: ProcessStatus.idle,
    }));
