import { createStore, combineReducers, EmptyObject, Store } from "redux";
import { MailAction, mailReducer, MailState } from "../reducers/mailReducers";
import {
  SideMenuState,
  sideMenuReducer,
  SideMenuAction,
} from "../reducers/sideMenuReducers";
export type AppState = {
  sideMenuState: SideMenuState;
  mailState: MailState;
};

export type AppAction = SideMenuAction | MailAction;

export const store = createStore(
  combineReducers<AppState, AppAction>({
    sideMenuState: sideMenuReducer,
    mailState: mailReducer,
  })
);
export const getStore: () => Store<EmptyObject & AppState, AppAction> = () =>
  store;
