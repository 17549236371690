exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alki-tsx": () => import("./../../../src/pages/alki.tsx" /* webpackChunkName: "component---src-pages-alki-tsx" */),
  "component---src-pages-asset-management-compound-interest-calculator-tsx": () => import("./../../../src/pages/asset-management/compound-interest/calculator.tsx" /* webpackChunkName: "component---src-pages-asset-management-compound-interest-calculator-tsx" */),
  "component---src-pages-asset-management-compound-interest-description-tsx": () => import("./../../../src/pages/asset-management/compound-interest/description.tsx" /* webpackChunkName: "component---src-pages-asset-management-compound-interest-description-tsx" */),
  "component---src-pages-asset-management-down-payment-tsx": () => import("./../../../src/pages/asset-management/down-payment.tsx" /* webpackChunkName: "component---src-pages-asset-management-down-payment-tsx" */),
  "component---src-pages-asset-management-loan-basic-knowledge-tsx": () => import("./../../../src/pages/asset-management/loan/basic-knowledge.tsx" /* webpackChunkName: "component---src-pages-asset-management-loan-basic-knowledge-tsx" */),
  "component---src-pages-asset-management-loan-calculator-tsx": () => import("./../../../src/pages/asset-management/loan/calculator.tsx" /* webpackChunkName: "component---src-pages-asset-management-loan-calculator-tsx" */),
  "component---src-pages-asset-management-loan-equal-repayment-of-principal-and-interest-tsx": () => import("./../../../src/pages/asset-management/loan/equal-repayment-of-principal-and-interest.tsx" /* webpackChunkName: "component---src-pages-asset-management-loan-equal-repayment-of-principal-and-interest-tsx" */),
  "component---src-pages-asset-management-loan-equal-repayment-of-principal-tsx": () => import("./../../../src/pages/asset-management/loan/equal-repayment-of-principal.tsx" /* webpackChunkName: "component---src-pages-asset-management-loan-equal-repayment-of-principal-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-sent-tsx": () => import("./../../../src/pages/contact/sent.tsx" /* webpackChunkName: "component---src-pages-contact-sent-tsx" */),
  "component---src-pages-dictionary-sa-line-tsx": () => import("./../../../src/pages/dictionary/sa-line.tsx" /* webpackChunkName: "component---src-pages-dictionary-sa-line-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-makeimage-tsx": () => import("./../../../src/pages/makeimage.tsx" /* webpackChunkName: "component---src-pages-makeimage-tsx" */),
  "component---src-pages-math-limit-definition-tsx": () => import("./../../../src/pages/math/limit/definition.tsx" /* webpackChunkName: "component---src-pages-math-limit-definition-tsx" */),
  "component---src-pages-math-numerical-sequence-arithmetic-sequence-tsx": () => import("./../../../src/pages/math/numerical-sequence/arithmetic-sequence.tsx" /* webpackChunkName: "component---src-pages-math-numerical-sequence-arithmetic-sequence-tsx" */),
  "component---src-pages-math-numerical-sequence-definition-tsx": () => import("./../../../src/pages/math/numerical-sequence/definition.tsx" /* webpackChunkName: "component---src-pages-math-numerical-sequence-definition-tsx" */),
  "component---src-pages-math-numerical-sequence-geometric-sequence-tsx": () => import("./../../../src/pages/math/numerical-sequence/geometric-sequence.tsx" /* webpackChunkName: "component---src-pages-math-numerical-sequence-geometric-sequence-tsx" */),
  "component---src-pages-math-numerical-sequence-infinite-geometric-series-tsx": () => import("./../../../src/pages/math/numerical-sequence/infinite-geometric-series.tsx" /* webpackChunkName: "component---src-pages-math-numerical-sequence-infinite-geometric-series-tsx" */),
  "component---src-pages-math-numerical-sequence-sigma-tsx": () => import("./../../../src/pages/math/numerical-sequence/sigma.tsx" /* webpackChunkName: "component---src-pages-math-numerical-sequence-sigma-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-system-trade-1-1-1-stock-exchanges-tsx": () => import("./../../../src/pages/system-trade/1/1/1-stock-exchanges.tsx" /* webpackChunkName: "component---src-pages-system-trade-1-1-1-stock-exchanges-tsx" */),
  "component---src-pages-system-trade-1-1-2-fx-exchanges-tsx": () => import("./../../../src/pages/system-trade/1/1/2-fx-exchanges.tsx" /* webpackChunkName: "component---src-pages-system-trade-1-1-2-fx-exchanges-tsx" */),
  "component---src-pages-system-trade-1-1-3-cryptocurrency-exchanges-tsx": () => import("./../../../src/pages/system-trade/1/1/3-cryptocurrency-exchanges.tsx" /* webpackChunkName: "component---src-pages-system-trade-1-1-3-cryptocurrency-exchanges-tsx" */),
  "component---src-pages-system-trade-1-2-python-development-environment-tsx": () => import("./../../../src/pages/system-trade/1/2-python-development-environment.tsx" /* webpackChunkName: "component---src-pages-system-trade-1-2-python-development-environment-tsx" */),
  "component---src-pages-system-trade-1-3-git-tsx": () => import("./../../../src/pages/system-trade/1/3-git.tsx" /* webpackChunkName: "component---src-pages-system-trade-1-3-git-tsx" */),
  "component---src-pages-system-trade-introduction-index-tsx": () => import("./../../../src/pages/system-trade/introduction/index.tsx" /* webpackChunkName: "component---src-pages-system-trade-introduction-index-tsx" */)
}

