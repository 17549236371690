import * as path from "path";
import {
  IconDefinition,
  faHome,
  faSearch,
  faEnvelope,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { Reducer } from "redux";
import "../lib/extensions/stringExtensions";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import allPages from "../data/page.json";
import allPageCategories from "../data/pageCategory.json";
import { Page } from "../hooks/useAllPage";
import { PageCategory } from "../hooks/useAllPageCategory";
export interface NavLink {
  text: string;
  icon?: IconDefinition;
  url: string;
}
export interface Category {
  isOpen: boolean;
  categoryId: number;
  categoryName: string;
  children: Array<Category | NavLink>;
}
function convertToCategory(pageCategory: PageCategory): Category {
  return {
    categoryId: pageCategory.pageCategoryId,
    categoryName: pageCategory.title,
    isOpen: false,
    children: [],
  };
}
export function isCategory(
  categoryLink: Category | NavLink
): categoryLink is Category {
  return "categoryName" in categoryLink;
}
function filterPageCategoryPages(
  links: Array<Category | NavLink>,
  text: string
): Array<Category | NavLink> {
  if (links === null || String.isNullOrEmpty(text)) return links;
  const result = _.cloneDeep(links);
  for (let i = 0; i < result.length; i++) {
    const link = result[i];
    if (isCategory(link)) {
      link.children = filterPageCategoryPages(link.children, text);
      if (link.children.length === 0) {
        result.splice(i, 1);
        i--;
      } else {
        link.isOpen = true;
      }
    } else if (!link.text.includes(text)) {
      result.splice(i, 1);
      i--;
    }
  }
  return result;
}
function filterMainMenues(
  mainMenues: Array<NavLink>,
  text: string
): Array<NavLink> {
  return mainMenues.filter((link) => link.text.includes(text));
}
function findCategory(
  links: Array<Category | NavLink>,
  categoryId: number
): Category {
  for (const link of links) {
    if (!isCategory(link)) continue;
    if (link.categoryId === categoryId) return link;
    const childResult = findCategory(link.children, categoryId);
    if (childResult) return childResult;
  }
  return null;
}
function getCategoriesCount(categoryLinks: Array<Category | NavLink>): number {
  let count = 0;
  categoryLinks.forEach((categoryLink) => {
    count += isCategory(categoryLink)
      ? getCategoriesCount(categoryLink.children)
      : 1;
  });
  return count;
}
function getSearchCount(
  categoryMenues: Array<Category | NavLink>,
  mainMenues: Array<NavLink>
): number {
  return getCategoriesCount(categoryMenues) + mainMenues.length;
}

function setChildren(
  root: string,
  category: Category | NavLink,
  allPageCategories: Array<PageCategory>,
  allPages: Array<Page>
): Category {
  if (!isCategory(category)) return;
  allPageCategories
    .filter((m) => m.parentCategoryId === category.categoryId)
    .forEach((pageCategory) => {
      const c = convertToCategory(pageCategory);
      setChildren(
        path.join(root, pageCategory.path),
        c,
        allPageCategories,
        allPages
      );
      category.children.push(c);
    });
  const pages = allPages
    .filter((page) => page.pageCategoryId === category.categoryId)
    .map<NavLink>((page) => ({
      text: page.title,
      url: path.join(root, page.path),
    }));
  category.children.push(...pages);
  return category;
}

function getAllPageCategoryLinks(
  allPageCategories?: Array<PageCategory>,
  allPages?: Array<Page>
): Array<Category | NavLink> {
  if (!allPageCategories || !allPages) return null;

  return allPageCategories
    .filter((m) => m.parentCategoryId === null && m.pageCategoryId !== 0)
    .map((m) => {
      const pageCategoryPage: Category | NavLink = {
        isOpen: false,
        categoryId: m.pageCategoryId,
        categoryName: m.title,
        children: [],
      };
      setChildren(`/${m.path}`, pageCategoryPage, allPageCategories, allPages);
      return pageCategoryPage;
    });
}

//allPageCategories,allPagesはStaticQueryから取得する場合、FunctionComponentからしか呼べないためInitialStateでの設定が出来なかったので、jsonを直接読み込む
const allMainMenues: Array<NavLink> = [
  { text: "ホーム", url: "/", icon: faHome },
  { text: "検索", url: "/search/", icon: faSearch },
  { text: "ご依頼", url: "/contact/", icon: faEnvelope },
  { text: "著者", url: "/profile/", icon: faUser },
];
const allCategoryLinks = getAllPageCategoryLinks(allPageCategories, allPages);

const actionCreator = actionCreatorFactory("SIDE_MENU");
export const search = actionCreator<string>("SEARCH");
export const toggleMenu = actionCreator<number>("TOGGLE_MENU");

export type SideMenuAction = typeof search | typeof toggleMenu;
export interface SideMenuState {
  searchText: string;
  searchCount: number;
  allMainMenues: Array<NavLink>;
  mainMenues: Array<NavLink>;
  allcategoryLinks: Array<Category | NavLink>;
  categoryLinks: Array<Category | NavLink>;
}

const initialState: SideMenuState = {
  searchCount: 0,
  searchText: "",
  allMainMenues: allMainMenues,
  allcategoryLinks: allCategoryLinks,
  mainMenues: allMainMenues,
  categoryLinks: allCategoryLinks,
};

export const sideMenuReducer: Reducer<SideMenuState, SideMenuAction> =
  reducerWithInitialState(initialState)
    .case(search, (state, searchText) => {
      const mainMenues = filterMainMenues(state.allMainMenues, searchText);
      const categoryLinks = filterPageCategoryPages(
        state.allcategoryLinks,
        searchText
      );
      return {
        ...state,
        searchText: searchText,
        searchCount: getSearchCount(categoryLinks, mainMenues),
        mainMenues: mainMenues,
        categoryLinks: categoryLinks,
      };
    })
    .case(toggleMenu, (state, categoryId) => {
      const categoryLinks = _.cloneDeep(state.categoryLinks);
      const current = findCategory(categoryLinks, categoryId);
      current.isOpen = !current.isOpen;
      return {
        ...state,
        categoryLinks: categoryLinks,
      };
    });
