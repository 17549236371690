/* eslint-disable no-undef */
import "prismjs/themes/prism-dark.min.css";
import "./src/styles/base/_typography.scss";
import "./src/styles/variables/_font-family.scss";
import "./src/styles/vendors/_reset.scss";
import wrapWithProvider from "./wrap-with-provider";
export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = () => {
  if (MathJax.typesetPromise !== undefined) {
    return MathJax.typesetPromise(null);
  }
};
